import React from "react"
import GalleriMat from "../components/bilder/GalleriMat"

import Layout from "../components/layout"
import SEO from "../components/seo"

function MatPage() {
  return (
    <Layout>
      <SEO
        keywords={[`Jens`, `Nordström`, `Fotograf`, `Malmö`]}
        title="Mat &amp; dryck"
      />

      <section className="">
        <GalleriMat />
      </section>
    </Layout>
  )
}

export default MatPage
